import React, { memo, useEffect, useRef, useState } from 'react'
import useTranslation from 'next-translate/useTranslation'
import { capitalizeFirstLetter } from '@/utils/utils'

function SearchPropertyType({ propertyTypes, css, label, handlePropertyTypeSelect }) {
  const { lang } = useTranslation('translations')
  const propRef = useRef(null);
  const [allPropertyTypes,setAllPropertyTypes] = useState(propertyTypes || []);
  useEffect(()=>{
    if(allPropertyTypes && propertyTypes && allPropertyTypes?.[0]?.whichType !== propertyTypes?.[0]?.whichType){
      propRef?.current?.scrollTo(0,0);
      setAllPropertyTypes(propertyTypes)
    }
  },[propertyTypes])
 
    return (
        <>
        <label className={css.label}>{label}</label>
        <ul ref={propRef} id = 'propertyTypes' className={css.residentialType}>
          {propertyTypes && propertyTypes?.map((obj,index)=><>
            <li onClick={(e)=>handlePropertyTypeSelect(e,index)}><button type='button' id={`${obj?.title.toLowerCase()}`} className={obj.isSelected ? css.selected:''}>{lang==='en'? capitalizeFirstLetter(obj?.title?.toLowerCase() ?? '') : obj?.title ?? ''}</button></li>
          </>)}
          </ul>
        </>
    )
}

export default memo(SearchPropertyType)