import React from 'react'
import styles from './CustomToggle.module.scss'

const CustomToggle = (props) => {
  const { customClass, text, onChangeFunction, check ,tag} = props
  const Tag = tag || 'p'

  return (
    <div className={`${customClass[0] && styles[customClass[0]]}`}>
      {React.createElement(Tag, null, text)}
      <label className={`${customClass[1] && styles[customClass[1]]}`}>
        <input type='checkbox' onChange={onChangeFunction} checked={check} />
        <span id = 'toggleCheck' className={`${customClass[2] && styles[customClass[2]]}`}></span>
      </label>
    </div>
  )
}

export default CustomToggle
