import React, { useEffect, useState }  from 'react'
import IconoGraphy from '../../IconoGraphy'
import { searchAnalyticEvent } from 'services/searchService'
import { useAppContext } from 'context/AppContext'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import { getParamsFromQuery } from 'services/queryString'
import { pushDataLayerEventHandler } from '@/utils/utils'
import Loader from '../../Loader'
import css from './mobileSearchPopup.module.scss'
import SearchPropertyTypeV2 from '../SearchPropertyTypeV2'
import { commercialAndResidentialPropertyTypeOptions } from '@/utils/constant'
import CustomToggle from '../../CustomToggle'
export default function MobileSearchV2({
  setInputValue,
  isMobileOnly,
  toggleSearchableModal,
  pageName,
  selectedCity,
  inputValue,
  tabs,
  handlePropertyIdChange,
  changePropertyType,
  onSearch,
  handleCityClick,
  handleDistrictClick,
  oldValArr
}) {
  const router = useRouter()
  const { state, dispatch } = useAppContext()
  const { searchReducer , homeSearchReducer} = state
  const selectedDistricts = homeSearchReducer?.selectedDistrictsMsite
  const { t, lang } = useTranslation('translations')
  const { query } = router
  const routerParams = getParamsFromQuery({ ...query })
  const [ isCommercial,setIsCommercial] = useState(searchReducer?.propertyType==='commercial' || (pageName==="SRPpage" && routerParams?.type==='commercial') || false)
  const [isSearching,setIsSearching] = useState(false);
  const [prevData,setPrevData] = useState({})
useEffect(()=>{
  if(homeSearchReducer?.allPropertyTypes && homeSearchReducer?.allPropertyTypes?.length!==0){
    if(homeSearchReducer?.allPropertyTypes[0].whichType!==searchReducer?.propertyType){
      dispatch({type:"ALL_PROPERTY_TYPES", payload:commercialAndResidentialPropertyTypeOptions(t)?.[searchReducer?.propertyType || 'residential']})
    }
  }
    else{
      dispatch({type:"ALL_PROPERTY_TYPES", payload:commercialAndResidentialPropertyTypeOptions(t)?.[searchReducer?.propertyType || 'residential']})
    }
    if(pageName==='SRPpage'){
      actionFunction(routerParams?.propertyFor || 'sale',true)
    }
  return () =>handleDefaultReset() //Reset on search or back click
},[])
useEffect(()=>{
if(pageName==='SRPpage' && routerParams?.districtId && oldValArr && oldValArr?.length!==0 && oldValArr[0].districtId){
  dispatch({type:"REMOVE_ALL_SELECTED_DISTRICT",payload: []});
  for(let i = 0; i<oldValArr?.length;i++){
    dispatch({ type: "SELECTED_DISTRICTS", payload: oldValArr[i] })
  }
}
},[])
useEffect(()=>{
  if(pageName==='SRPpage'){
  const prevCity = {
    "propertyFor": searchReducer?.propertyFor,
    "title": searchReducer?.selectedItem?.cityName[0],
    "countryId": 1,
    "cityId": searchReducer?.selectedItem?.cityId[0],
    "districtId": null,
    "city": searchReducer?.selectedItem?.cityName[0],
    "srpTitle": searchReducer?.selectedItem?.cityName[0],
    "category": searchReducer?.propertyType,
    "locationType": searchReducer?.selectedItem?.locationType, 
    "whichSelected":searchReducer?.selectedItem?.locationType
}
setPrevData({ prevCity:prevCity, prevPropertyTypes: JSON.parse(JSON.stringify(searchReducer?.allPropertyTypes))}) //Creating a shallow copy
  dispatch({type:'UPDATE_PROPERTYTYPE',payload:routerParams?.type || 'residential'})
}
},[])
  const actionFunction = (tabname,defaultReset=false)=>{
    handlePropertyIdChange(null,tabname)
    changePropertyType(tabname === 'sale' ? 'sale' : 'rent')
    dispatch({type:'UPDATE_PROPERTYFOR_MSITE',payload:tabname})
    dispatch({type:'UPDATE_PROPERTYFOR',payload:tabname})
    const page = pageName === 'SRPpage' ? 'SRP-List' : 'Home Page'
    const data = {
      service: tabname,
      placement: pageName ==='SRPpage' ? 'SRP-List' : 'Search_screen'
    }
   !defaultReset && pushDataLayerEventHandler(window, lang, 'Change Service', page, data)
  }

  const handlePropertyTypeSelection = (tabname,defaultReset=false) =>{
    setIsCommercial(tabname==='commercial')
    dispatch({type:'UPDATE_PROPERTYTYPE_MSITE',payload:tabname})
    dispatch({type:'UPDATE_PROPERTYTYPE',payload:tabname})
    const page = pageName === 'SRPpage' ? 'SRP-List' : 'Home Page'
    const data = {
      status: tabname === 'commercial',
      placement: pageName ==='SRPpage' ? 'SRP-List' : 'Search_screen',
      change_to: tabname,
    }
    const selectedType = searchReducer?.propertyType == 'residential' ? 'commercial' : 'residential'
    dispatch({type: pageName === 'SRPpage' ? "SET_ALL_PROPERTY_TYPES" : "ALL_PROPERTY_TYPES" , payload:commercialAndResidentialPropertyTypeOptions(t)?.[defaultReset ? 'residential' : selectedType]})
    !defaultReset && pushDataLayerEventHandler(window, lang, 'view_commercial_prop', page, data)
  }

  const onChangeToggle =()=>{
    handlePropertyTypeSelection(!isCommercial ? 'commercial':'residential')
  }
    const handlePropertyTypeSelect = (e, index) =>{
      e.stopPropagation()
      e.preventDefault();
      let tempPropType = pageName=='SRPpage'? [...searchReducer?.allPropertyTypes] : [...homeSearchReducer?.allPropertyTypes];
      tempPropType[index].isSelected = !tempPropType[index].isSelected;
      pageName=='SRPpage'? dispatch({type:"SET_ALL_PROPERTY_TYPES", payload:tempPropType}) : dispatch({type:"ALL_PROPERTY_TYPES", payload:tempPropType})
    }
  const handleDefaultReset = () => {
    if(pageName!=='SRPpage'){
      setInputValue('')
      selectedCity({});
      dispatch({type:"REMOVE_ALL_SELECTED_DISTRICT",payload: []});
      dispatch({ type: "SET_ALL_DISTRICTS", payload: [] })
      actionFunction('sale',true);
      handlePropertyTypeSelection('residential',true)
    }
  }
  const handleSearch = () =>{
    let cityValues
    if(pageName==='SRPpage') {
      if(!searchReducer?.selectedItem || !searchReducer?.selectedItem?.cityId || !searchReducer?.selectedItem?.cityId[0]){
        isMobileOnly && toggleSearchableModal()
        return;
      }
     cityValues = searchReducer?.selectedItem && searchReducer?.selectedItem?.cityName?.length!==0 && searchReducer?.selectedItem?.cityName[0] ? searchReducer?.selectedItem : {cityId:''}
    } else {
     cityValues = searchReducer?.selectedItem?.countryName && searchReducer?.selectedItem?.countryName?.length!==0 && searchReducer?.selectedItem?.countryName[0]? searchReducer?.selectedItem : {cityId:273}
    }  
  onSearch && onSearch(cityValues,{districtValue:selectedDistricts,propertyType:pageName === 'SRPpage' ? searchReducer?.allPropertyTypes : homeSearchReducer?.allPropertyTypes}); 
  if(pageName==='SRPpage'){
    isMobileOnly && toggleSearchableModal()
    dispatch({type:"REMOVE_ALL_SELECTED_DISTRICT",payload: []})
    dispatch({ type: "SET_ALL_DISTRICTS", payload: [] })
  }
  setIsSearching(true);
  let cityData;
  const searchCityName = pageName!=='SRPpage' ? searchReducer?.selectedItem?.countryName : searchReducer?.selectedItem?.cityName
  if(searchCityName && searchCityName?.length!==0 && searchCityName[0]){
    cityData={
      city_id: searchReducer?.selectedItem?.cityId[0] || 273,
      city: searchCityName[0] || t('Home.RIYADH'),
      popular_city: searchReducer?.selectedItem?.popularCity ?? true
    }
  } else{
      cityData={
        city_id: 273,
        city: t('Home.RIYADH'),
        popular_city: true
      }
    }
    let districtData={district:[],districtId:[]}
    if(selectedDistricts && selectedDistricts?.length!=0){
      selectedDistricts?.map((val)=>{
        districtData={
          district:[...districtData?.district,val?.title],
          districtId:[...districtData?.districtId,val?.districtId]
        }
      })
    }
    const page = pageName=='SRPpage' ? 'SRP-List' : 'Home Page'
    const selectedReducer = pageName=='SRPpage' ? searchReducer : homeSearchReducer;
    const allSelectedPropertyTypeName = selectedReducer?.allPropertyTypes ? String(selectedReducer?.allPropertyTypes?.filter((value)=>value.isSelected)?.map((obj)=>obj?.name)) : '' //Selected properties name
    const data ={
      ...cityData,
      district: districtData && districtData?.district && districtData?.district?.length!==0 ? String(districtData?.district) :'',
      district_id: districtData && districtData?.districtId && districtData?.districtId?.length!==0 ? String(districtData?.districtId) :'',
      service: searchReducer?.propertyFor || 'sale',
      property_type: allSelectedPropertyTypeName,
      view_commercial_prop:isCommercial,
      nearby_district : selectedDistricts?.find((district)=>district?.regionId && district?.locationType && district?.distance ) ? true : false , // distance present in nearby districts
      popular_district : (selectedDistricts?.find((district)=>!district?.distance ) && selectedDistricts[0]?.locationType !== 'city') ?? false // distance not presnt in popular
    }
    if(allSelectedPropertyTypeName && allSelectedPropertyTypeName!==''){
      const propertyData={
        property_type: allSelectedPropertyTypeName, 
        placement: pageName ==='SRPpage' ? 'SRP-List' : 'Search_screen'
      }
      pushDataLayerEventHandler(window, lang, 'property_type_selected',page, propertyData)
    }
    pushDataLayerEventHandler(window, lang, 'search', page, data)
  }
  const handleClearClick = () =>{
    dispatch({type:"REMOVE_ALL_SELECTED_DISTRICT",payload: []});
    dispatch({ type: "SET_ALL_DISTRICTS", payload: []});
  }
  const handleBackClick = () =>{
    if(pageName==='SRPpage'){
      dispatch({type:"REMOVE_ALL_SELECTED_DISTRICT",payload: []});
      dispatch({ type: "SET_ALL_DISTRICTS", payload: []});
      handlePropertyTypeSelection(routerParams?.type, true);
      selectedCity(prevData?.prevCity || {}, true)
      dispatch({type:"SET_ALL_PROPERTY_TYPES", payload:prevData?.prevPropertyTypes || []})
    }
  }
  const displayCityValue = () =>{
    if(pageName!=='SRPpage'){
      return searchReducer?.selectedItem?.countryName[0] || searchReducer?.selectedItem?.cityName[0]|| t('Home.RIYADH')
    }
    else if(pageName==='SRPpage' && searchReducer?.selectedItem?.cityId && searchReducer?.selectedItem?.cityId[0]){
      return searchReducer?.selectedItem?.cityName[0];
    }
    else{
      return ''
    }
  } 
  return (
    <div className={css.popupWrapper}>
      <div className={css.modalTop}>
        <button 
          id = 'closeSearchButton'
          className={css.close}
          onClick={() => {
            pageName==='SRPpage' && handleBackClick();
            (isMobileOnly && toggleSearchableModal())
            searchAnalyticEvent(searchReducer?.selectedItem, '', inputValue, window, lang, 'search_back')
          }}
        >
          <IconoGraphy iconClass='icon-close'  iconColor='color_gallery_popup_close'  fontSize='f_8'/>
        </button>
        <h2>{t('Search.HOME_SEARCH')}</h2>
      </div>
      <div className={css.modalBody}>
        <div className={css.modalCard}>
          <div className={css.TabPen}>
            {tabs.map((tabname, index) => (
              <div
                id = {tabname.key}
                key={index}
                className={searchReducer?.propertyFor === tabname.key ? `${css.tabButton} ${css.active}` : `${css.tabButton}`}
                onClick={() => {
                  actionFunction(tabname.key);
                }}
              >
                {tabname?.translation}
              </div>
            ))}
          </div>
          <CustomToggle
                customClass={[
                  'commercialTab',
                  'switch',
                  'slider'
                ]}
                text={t('NewPropertySearch.VIEW_COMMERCIAL_PROP_ONLY')}
                onChangeFunction={()=>onChangeToggle()}
                check={isCommercial}
              />
        </div>

        <div className={css.modalCard}>
          <label className={css.label}>{t("Search.CITY")}</label>
          <div className={css.citySearchWrap}>
            <input id = 'citySearch' placeholder={t('NewPropertySearch.SELECT')} value={displayCityValue()} onFocus={handleCityClick} className={css.citySearch}/>
            <IconoGraphy iconClass='icon-arrow-down' fontSize='f_12' iconColor='color_lighBlack'/>
          </div>
        </div>

      {(pageName!=='SRPpage' || pageName==='SRPpage' && searchReducer?.selectedItem?.cityId && searchReducer?.selectedItem?.cityId[0]) && <div className={css.modalCard}>
          <label className={css.label}>{t("NewPropertySearch.DISTRICT")}</label>
          <div className={css.distSearchWrap} onClick={handleDistrictClick}>
            <IconoGraphy iconClass='icon-search-new' fontSize='f_16'/>
          <input id = 'districtSearch' placeholder={`${t("NewPropertySearch.SEARCH_DISTRICTS_IN")} ${searchReducer?.selectedItem?.countryName[0] || searchReducer?.selectedItem?.cityName[0] || t('Home.RIYADH')}`} className={css.distSearch}/>
          </div>
         {selectedDistricts?.length!==0 && 
         <div className={css.selectedDist}>
            {selectedDistricts?.map((dist,index)=>
            <button id = {`district-${index}`} key = {index} className={css.btn}> 
              {dist?.srpTitle}
              <span id='removeSelection'>
              <IconoGraphy
                iconClass='icon-close'
                iconColor='color_purple'
                fontSize='f_7'
                onClickHandler={()=>dispatch({type:"REMOVED_DISTRICT",payload: dist})}
              />
              </span>
            </button>
            )}
          {selectedDistricts?.length > 1 && <button id ='clearAll' className={css.clearAll} onClick={()=>handleClearClick()}>{t("Search.CLEARALL")}</button> }
          </div> }
        </div> }

        <div className={css.modalCard}>
          <SearchPropertyTypeV2 css={css} label={t("NewPropertySearch.PROPERTY_TYPE_HEADING")} propertyTypes= {pageName==='SRPpage'? searchReducer?.allPropertyTypes : homeSearchReducer?.allPropertyTypes} handlePropertyTypeSelect = {handlePropertyTypeSelect}/>
        </div>
      </div>

      <div className={css.buttons}>
        <button id = 'viewPropertiesButton' type='button' className={css.viewProperties} onClick={()=>handleSearch()}>{!isSearching ? t("Home.VIEW_PROPERTIES") : <Loader/>}</button>
      </div>
    </div>
  )
}