import React from 'react'
import styles from './ModalPopup.module.scss'

const ModalPopup = (props) => (
    <div onTouchEnd={(e)=> e.stopPropagation()} className={`${styles.mapPopup} ${styles.searchsugwrap} `}>
      {props.childComponent}
    </div>
)

export default ModalPopup
