import React, { useEffect }  from 'react'
import IconoGraphy from '../../IconoGraphy'
import TopLocations from '../TopLocations/TopLocations'
import { searchAnalyticEvent } from 'services/searchService'
import { useAppContext } from 'context/AppContext'
import useTranslation from 'next-translate/useTranslation'
import Loader from '../../Loader'
import css from './mobileCitySearch.module.scss'
import RecentSearchNew from '../RecentSearchNew'

export default function MobileCitySearch({
  setShowSuggestion,
  onChnageForDistrict,
  setToggleNRF,
  setInputValue,
  isMobileOnly,
  setWrapperOpen,
  pageName,
  selectedCity,
  dropDownData,
  getHighlightedText,
  inputValue,
  toggleNRF,
  popularCities,
  selectedProperty,
  onSearch,
  searchLoader,
  setSearchLoader,
  toggleCityBackClick,
  showHomeCitySearchLoaderMsite=false,
  propertyType
}) {
  const { state, dispatch } = useAppContext()
  const { searchReducer ,commonReducer : {isDropDownDataEmpty}} = state
  const { t, lang } = useTranslation('translations')

  let listItems = '' 
  let content = searchLoader ? <div className={css.loaderHomepageMobile}><Loader color={true} /></div> : '';


  useEffect(()=>{
    setInputValue('')
  },[])

  const changeFn = (e) => {
    setInputValue(e.target.value.trimStart())
    setWrapperOpen(true)
    onChnageForDistrict(e,true)
    setToggleNRF(true)
    if(inputValue?.length<2 && isDropDownDataEmpty) {
                      
      dispatch({ type: 'SET_IS_DROP_DOWN_DATA_EMPTY', payload: false })
    } 
  }
  const focusFn = () => {
    setWrapperOpen(true)
    setShowSuggestion(true)
    setToggleNRF(false)
  }

  if (dropDownData && dropDownData?.length) {
    listItems = dropDownData?.slice(0, 10)?.filter((f) => f?.searchType === 'property')?.map((property, i) => (
        <li className={`${css.idNumber} ${i === 0 && css.idtopmargin}`} key={property.id} onClick={() => { selectedProperty(property) }}>
          <span className={css.idLabel}>{t('Home.ID')}: </span>
          <span className={css.idText}>
            {getHighlightedText(property.darReference, inputValue)}
          </span>{' '}
          <p className={css.idTitle}>{property.title}</p>
        </li>
      ));
  }

  if (inputValue?.length >= 2 && dropDownData.length) {
    content = dropDownData.slice(0, 10)?.filter((f) => f?.searchType !== 'property' && !f?.districtId)?.map((val) => (
        <div
          className={css.suggestion_list}
          onClick={() => {
            selectedCity(val, pageName === 'SRPpage');
            dispatch({ type: "REMOVE_ALL_SELECTED_DISTRICT", payload: [] });
            dispatch({ type: "SET_ALL_DISTRICTS", payload: [] });
            isMobileOnly && toggleCityBackClick();
          }}
        >
          <div className={css.cityWrap}>
            <span className={css.searchIcon}>
              <IconoGraphy iconClass='icon-map-marker' iconColor='color_gallery_popup_close' fontSize='f_20' />
            </span>
            <span className={css.cityName}>{getHighlightedText(val?.title, inputValue)}</span>
          </div>
        </div>
      ));
  } 
  return (
    <div className={css.citySearchWrap}>
      <div className={css.searcharea}>
        <div className={css.searchBox}>
          <span 
            className={css.searchIconPlaceholder}
            onClick={() => {
              (isMobileOnly && toggleCityBackClick())
              searchAnalyticEvent(searchReducer?.selectedItem, '', inputValue, window, lang, 'search_back')
            }}
          >
            <IconoGraphy
              iconClass='icon-chevron-left'
              iconColor='color_gray_darker'
              fontSize='f_10'
            />
          </span>
          <input
            type='text'
            name='searchBox'
            id = 'citySearchInput'
            placeholder={t('Search.CHOOSE_CITY')}
            value={inputValue}
            className={css.searchcity}
            onChange={(e) => {
              changeFn(e)
            }}
            onFocus={() => {
              focusFn()
              searchAnalyticEvent({}, '', '', window, lang, 'search_box_tap')
            }}
            onBlur={() => {
              setShowSuggestion(false)
            }}
            autoComplete='off'
            autoFocus={true}
          />
          {inputValue && (
            <div className={css.loaderWrap}>
              {showHomeCitySearchLoaderMsite ? <Loader color={true}/> :
              <IconoGraphy
                iconClass='icon-close'
                iconColor='color_gallery_popup_close'
                fontSize='f_8'
                onClickHandler={() => {
                  setInputValue('')
                  setSearchLoader && setSearchLoader(false)
                }}
              />
}
            </div>
          )}
        </div>
      </div>
      
      <div className={css.scrollingDiv}>
      {!inputValue && (  
        <>
         <RecentSearchNew
          propertyType = {propertyType}
          setInputValue={(val)=>selectedCity(val,pageName==='SRPpage')}
          searchType = {'city'}
          toggleCityBackClick={toggleCityBackClick}
          
        />
          <TopLocations title={t('Search.TOP_LOCATIONS')} top_locations={popularCities} selectedCity={(val)=>selectedCity(val,pageName==='SRPpage')} onSearch={onSearch} isHomeSearchMobile={true} toggleCityBackClick={toggleCityBackClick}/>
       </>
        )}

        {!isNaN(inputValue) && inputValue?.length >= 2 ? (
          <>
            {listItems}
          </>
        ) : (
          <>
           {!isDropDownDataEmpty && <div className={css.suggestionWrapper}>
              {content}
            </div> }
          </>
        )}
        {(isDropDownDataEmpty && toggleNRF && inputValue && inputValue.length>=2) ? (
                <div className={css.notFound}>
                  <p>{t('Search.NO_RESULT_FOUND')}</p>
                </div>
              ) : ''}
      </div>
      <div className={css.buttons}>
        <button id = 'continueButton' type='button' onClick={()=>isMobileOnly && toggleCityBackClick()}><span>{t('NewPropertySearch.CONTINUE')}</span></button>
      </div>
    </div>
  )
}